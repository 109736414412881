.faq-main-area-wrap {
  background: #1ab389;
  padding: 120px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .faq-title {
    margin-bottom: 60px;
    text-align: center;
    p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    h2 {
      color: #fff;
      font-size: 36px;
      font-weight: 800;
      line-height: 48px;
    }
  }
  .accordion-item {
    margin-bottom: 15px;
    border-radius: 15px !important;
    overflow: hidden;
    .accordion-button {
      &:not(.collapsed) {
        color: #000;
        font-weight: 600;
        background-color: #fff;
        box-shadow: unset !important;
      }
      &:focus {
        z-index: 3;
        border-color: none;
        outline: 0;
        box-shadow: unset !important;
      }
    }
  }
}

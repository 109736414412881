.footer-main-area-wrap {
  background: #00193d;
  padding: 70px 0;
  padding-bottom: 40px;
  hr {
    color: #fff;
  }
  .footer-main-top-area {
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 10px;
      width: 500px;
      margin: 20px auto;
    }
    img {
      height: 45px;
    }
  }
  .footer-social {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
    a {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      transition: all 0.3s ease 0s;
      &:hover {
        color: #00bcd4;
      }
    }
  }
  .footer-bottom-copyright-area {
    p {
      color: #fff;
      text-align: center;
      padding-top: 40px;
      a {
        color: red;
        font-size: 16px;
        font-weight: 500;
        transition: all 0.3s ease 0s;
        text-decoration: none;
        &:hover {
          color: #00bcd4;
        }
      }
    }
  }
}

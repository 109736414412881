.product-feature-main-area-wrap {
  padding: 80px 0;

  .product-feature-title {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #000;
    }
    p {
      color: #1ab389;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
  .single-product-feature {
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
    }
    .product-feature-icon {
      width: 60px;
      height: 60px;
      background: #1ab389 !important;
      i {
        color: #fff;
        font-size: 20px;

        svg {
          width: 20px;
          path {
            fill: #fff;
          }
        }
      }
    }
    &.left {
      text-align: right;
      margin-bottom: 60px;
      .product-feature-icon {
        width: 60px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 10%);
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-bottom: 25px;
      }
    }
    &.right {
      text-align: left;
      margin-bottom: 60px;
      .product-feature-icon {
        width: 60px;
        height: 60px;
        background: #fff;
        box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 10%);
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-bottom: 25px;
      }
    }
    &.middle {
      text-align: center;
      img {
        width: 80%;
        padding: 0px 30px;
        position: relative;
        margin: auto;
      }
    }
  }
}

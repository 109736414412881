.hero-main-area-wrap {
  position: relative;
  // padding-bottom: 230px;
  > img {
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .hero-inner-area-wrap {
    position: relative;
    // height: 100vh;
    .hero-top-area-content {
      text-align: center;
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h2 {
        font-size: 44px;
        font-weight: 800;
        line-height: 58px;
        margin-bottom: 30px;
      }
      .hero-top-bottom-button-area {
        display: flex;
        justify-content: center;
        gap: 20px;
        a {
          position: relative;
          &:first-child {
            background: #1ab389;
            padding: 12px 30px;
            color: #fff;
            text-decoration: none;
            border-radius: 10px;
          }
          &:last-child {
            background: #fff;
            padding: 12px 30px;
            color: #1ab389;
            border: 1px solid #1ab389;

            text-decoration: none;
            border-radius: 10px;
            transition: 0.2s;
            z-index: 11;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              //   background: #000;
              border-radius: 8px;
              z-index: -1;
              transition: 0.2s;
              border-right: 3px solid #1ab389;
              border-bottom: 3px solid #1ab389;
            }
            &:hover {
              &:after {
                border-right: 0px solid transparent;
                border-bottom: 0px solid transparent;
                border-top: 3px solid #1ab389;
                border-left: 3px solid #1ab389;
              }
            }
          }
        }
      }
    }
    .hero-bottom-area-image {
      display: flex;
      justify-content: center;
      img {
        max-height: 700px;
        width: unset;
      }
    }
  }
}

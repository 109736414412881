//media query
@media (max-width: 1100px) {
  .hero-main-area-wrap .hero-inner-area-wrap .hero-top-area-content h2 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  nav.navbar_mobile_menu.hide {
    position: absolute;
    top: 80px;
    background: #fff;
    width: 100%;
    transform: rotateX(90deg);
    z-index: -1;
    transition: all 0.4s cubic-bezier(0.24, 0.66, 0.54, 0.66) 0s;
    transform-origin: top center;
    .header-navbar {
      ul {
        flex-direction: column;
      }
    }
  }
  header.header-main-area .header-inner-wrap .menuToggle {
    display: block !important;
  }
  nav.navbar_mobile_menu.show {
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    transform: rotateX(0deg);
    transition: all 0.3s ease 0s;
    transform-origin: top center;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: #fff;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      background: #fff;
    }
    .header-navbar {
      background: #fff;
      padding: 10px;
      ul {
        display: block !important;
        li {
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
  .single-product-feature.middle {
    display: none;
  }
  .product-feature-main-area-wrap .single-product-feature {
    &.left {
      text-align: left;
      margin-bottom: 60px;
      .product-feature-icon {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }
  .mobile-display-none-feature-image {
    display: none;
  }
  .about-area-bottom-info {
    margin-top: 50px;
  }
  .about-left-area img {
    width: 80%;
    margin: auto;
  }

  .about-left-area {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 40px;
  }
  .work-area-main-wrap .nav.nav-tabs {
    justify-content: space-between;
    border-bottom: none;
    flex-wrap: wrap;
    li {
      width: 30%;
      text-align: center;
    }
  }
  .product-analytics-accordion-left {
    margin-bottom: 40px;
  }
  .product-analytics-main-area-wrap {
    padding-bottom: 0px;
  }
  .pricing-table-main-area-wrap .pricing-table-single-item {
    border: 1px solid #ddd;
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    background: #fff;
    margin-bottom: 30px;
  }
  .prduct-review-area-main-wrap .product-review-content {
    flex-direction: column;
    gap: 50px;
    > * {
      width: 100%;
    }
  }
  .prduct-review-area-main-wrap
    .product-review-content
    .review-content-devider {
    background: #000;
    width: 100% !important;
    height: 2px;
  }
  .work-area-main-wrap .row .col-lg-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .work-area-main-wrap .work-inner-single-items p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 0px 10px;
  }
  .work-area-main-wrap .nav-tabs .nav-link {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  body {
    .container {
      width: 100% !important;
      max-width: 100% !important;
    }
    .work-area-main-wrap .row .col-lg-4 {
      width: 50%;
    }
    .work-area-main-wrap .row {
      justify-content: unset;
    }
    .work-area-main-wrap .nav.nav-tabs {
      justify-content: space-between;
      border-bottom: none;
      flex-wrap: wrap;
      li {
        width: 50%;
        text-align: center;
      }
    }
  }
  .work-area-main-wrap .work-inner-single-items {
    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 15px;
      padding: 0px 30px;
      text-align: left;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      padding: 0px 30px;
      text-align: left;
    }
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-bottom-area-image img {
    max-height: 400px;
    width: unset;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-top-area-content h2 {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 44px;
    line-height: 54px;
  }
  .hero-main-area-wrap {
    position: relative;
    padding-bottom: 200px;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-bottom-area-image {
    margin-top: 80px;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-top-area-content {
    height: 60vh;
  }
  .hero-main-area-wrap > img {
    height: 85vh;
  }
  .hero-main-area-wrap .hero-inner-area-wrap {
    position: relative;
    height: 90vh;
  }
  .pricing-table-main-area-wrap
    .pricing-table-top-header-wrap
    .pricing-table-title
    h2 {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }
  .single-product-feature {
    box-shadow: 0px 0px 5px 0px #e9ecef96;
    margin-bottom: 30px !important;
    padding: 15px;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-bottom-area-image {
    margin-top: 0px;
  }
  .about-main-area-wrap > * > .row {
    flex-direction: column-reverse;
  }
  .about-main-area-wrap .about-area-bottom-info .about-bottom-single-item {
    text-align: center;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  body {
    .hero-main-area-wrap {
      position: relative;
      padding-bottom: 0px;
    }
    .container {
      width: 100% !important;
      max-width: 100% !important;
    }
    .work-area-main-wrap .row .col-lg-4 {
      width: 100%;
    }
    .work-area-main-wrap .row {
      justify-content: unset;
    }
    .work-area-main-wrap .nav.nav-tabs {
      justify-content: space-between;
      border-bottom: none;
      flex-wrap: wrap;
      li {
        width: 50%;
        text-align: center;
      }
    }
  }
  .work-area-main-wrap .work-area-title h2 {
    font-size: 32px;
    font-weight: 800;
    line-height: 48px;
  }
  .product-analytics-main-area-wrap .product-analytics-title h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 40px;
  }
  .about-main-area-wrap .about-right-area .about-content > h4 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
  }
  .product-feature-main-area-wrap .product-feature-title h2 {
    font-size: 34px;
    font-weight: 800;
    color: #000;
  }
  .pricing-table-main-area-wrap .pricing-table-top-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    flex-wrap: wrap;
    gap: 15px;
  }
  .prduct-review-area-main-wrap .review-feedback-information-wrap {
    padding: 0 0px;
  }
  .blog-main-area-wrap .blog-area-content > .row > * {
    width: 100%;
  }
  .blog-main-area-wrap .blog-bottom-content h4 {
    font-size: 25px;
    font-weight: 800;
    color: #000;
    margin-bottom: 10px;
  }
  .blog-single-item {
    margin-bottom: 30px;
  }
  .footer-main-area-wrap .footer-main-top-area p {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    width: auto;
    margin: 20px auto;
  }
  .our-pertner-main-area-wrap .our-pertner-title h6 {
    color: #1ab389;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .our-pertner-main-area-wrap .pertner-slider-single-item img {
    height: 40px;
    width: unset;
  }
  .faq-main-area-wrap .faq-title h2 {
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
  }
  .faq-main-area-wrap {
    padding: 50px 0;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-bottom-area-image img {
    max-height: 300px;
    width: unset;
  }
  .hero-main-area-wrap
    .hero-inner-area-wrap
    .hero-top-area-content
    .hero-top-bottom-button-area {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-top-area-content {
    height: 70vh;
  }
  .hero-main-area-wrap .hero-inner-area-wrap .hero-top-area-content h2 {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 40px;
    line-height: 42px;
  }
  .hero-main-area-wrap .hero-inner-area-wrap {
    position: relative;
    height: unset;
    min-height: 100vh;
  }
  .hero-main-area-wrap > img {
    height: 90vh;
  }
}

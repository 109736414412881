.about-main-area-wrap {
  padding: 80px 0;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .about-left-area img {
    width: 90%;
    margin-bottom: 25px;
  }
  .about-right-area {
    .about-title {
      p {
        color: #1ab389;
        margin-bottom: 15px;
        font-weight: 500;
      }
    }
    .about-content {
      > h4 {
        margin-bottom: 10px;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
      }
      > p {
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 22px;
      }
      hr {
        color: #f0f0f0;
        opacity: 1;
        margin-bottom: 0px;
      }
      .about-profile-info-bottom {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-top: 20px;
        .about-profile-info-bottom-left-icon img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .about-profile-info-bottom-left-content {
          p {
            font-size: 13px;
            margin-bottom: 6px;
          }
          h5 {
            font-size: 16px;
          }
        }
      }
    }

    .about-left-area img {
      width: 94%;
    }
  }
  .about-area-bottom-info {
    .about-bottom-single-item {
      text-align: center;
      h3 {
        margin-bottom: 10px;
        color: #1ab389;
        font-weight: 800;
      }
      p {
        color: #4e4e4e;
      }
      i {
        svg {
          width: 34px;
          height: 34px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.product-analytics-main-area-wrap {
  padding: 120px 0;
  .product-analytics-title {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 36px;
      font-weight: 800;
      line-height: 48px;
    }
    p {
      margin-bottom: 15px;
      color: #1ab389;
      font-weight: 500;
    }
  }
  .accordion-item {
    color: unset;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    .accordion-button {
      padding-left: 0;
      padding: 8px 10px;
      font-size: 16px;
      font-weight: 700;
      // padding-bottom: 0;
      &:focus {
        z-index: 3;
        border-color: none;
        outline: 0;
        box-shadow: none;
      }
      &:not(.collapsed) {
        background: #fff;
        border-color: #86b7fe;
        box-shadow: none;
        color: #000;
      }
    }

    .accordion-body {
      padding-top: 0;
      padding-left: 0;
    }
    .mobile-show-desktop-hide.accordion-body {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }
  }
  .product-analytics-right-area {
    max-height: 450px;
    // overflow-y: scroll;
    @media (max-width: 991px) {
      display: none;
    }
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  .accordion-button::after {
    content: "\f178";
    // font-family: "Font Awesome 5 Pro";
    font: var(--fa-font-solid);
    background: none;
    font-weight: 700;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
  }
}

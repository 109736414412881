.blog-main-area-wrap {
  padding: 80px 0;
  .blog-area-title {
    text-align: center;
    margin-bottom: 80px;
    p {
      font-weight: 500;
      color: #1ab389;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 36px;
      font-weight: 800;
      color: #000;
    }
  }
  .blog-bottom-content {
    padding: 20px 10px;
    padding-bottom: 15px;
    a {
      text-decoration: none;
      h4 {
        font-size: 24px;
        font-weight: 700;
        color: #000;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 13px;
      font-weight: 500;
      color: #000;
    }
  }
  .blog-content-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    & > * {
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        font-size: 22px;
        color: #ccced0;
      }
      span {
        font-size: 14px;
      }
      &:last-child {
        i {
          font-size: 22px;
          color: #1ab389;
        }
      }
    }
  }
}

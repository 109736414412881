@use "./global.scss";
@use "./hero.scss";
@use "./feature.scss";
@use "./about.scss";
@use "./product-analytics.scss";
@use "./works.scss";
@use "./faq.scss";
@use "./pricing.scss";
@use "./our-pertner.scss";
@use "./review-design.scss";
@use "./blog.scss";
@use "./footer.scss";
@use "./responsive.scss";

* {
  margin: 0;
}

.prduct-review-area-main-wrap {
  padding: 120px 0;
  background: #fafafb;
  .product-review-title {
    margin-bottom: 60px;
    text-align: center;
    p {
      font-weight: 500;

      margin-bottom: 15px;
      color: #1ab389;
    }
    h2 {
      font-size: 36px;
      font-weight: 800;
      line-height: 48px;
      color: #000;
    }
  }
  .playstore-icon {
    text-align: center;
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 50px;
      border: 2px solid #1ab389;
      padding: 10px;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 24px;
    }
    .feedback-rating {
      span {
        font-size: 18px;

        i {
          color: #f49e1c;
        }
      }
    }
  }
  .product-review-content {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0px 30px 60px #0000000d;
    > * {
      width: 40%;
    }
    .review-content-devider {
      width: 2px !important;
      background: #e1e1e1;
    }
  }

  .review-feedback-information-wrap {
    padding: 0 60px;
    .review-feedback-information {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      > * {
        width: 50%;
      }
    }
    .review-app-download-link {
      text-align: center;
      margin-top: 30px;
      a {
        padding: 12px 30px;
        background: #fff;
        border-radius: 10px;
        color: #1ab389;
        font-weight: 600;
        border: 1px solid #1ab389;
        position: relative;
        transition: all 0.3s ease 0s;
        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          transition: 0.2s;
          border-right: 3px solid #1ab389;
          border-bottom: 3px solid #1ab389;
        }
        &:hover:after {
          border-right: 0px solid transparent;
          border-bottom: 0px solid transparent;
          border-top: 3px solid #1ab389;
          border-left: 3px solid #1ab389;
        }
      }
    }
  }
}

//================================================================
body {
  font-family: "Manrope", sans-serif;
}
body > div {
  overflow: hidden;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
}
li {
  list-style: none;
}

hr {
  margin: 0;
}
img,
svg {
  vertical-align: middle;
  width: -webkit-fill-available;
}
p {
  margin-bottom: 0;
}

h1 {
  margin-bottom: 0;
}

h2 {
  margin-bottom: 0;
}

h3 {
  margin-bottom: 0;
}

h4 {
  margin-bottom: 0;
}

h5 {
  margin-bottom: 0;
}

h6 {
  margin-bottom: 0;
}
